.avatar {
  transition:all 0.4s ease-in-out;
  -webkit-transition:all 0.4s ease-in-out;
  -moz-transition:all 0.4s ease-in-out;
  -o-transition:all 0.4s ease-in-out;
  -ms-transition:all 0.4s ease-in-out;
}

.innerAnnotation {
  position: absolute;
  text-align: left;
  font-size: 14px;
  color: #999
}